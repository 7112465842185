<template>
  <appDialog
    btnText="导出交易记录"
    title="导出交易记录"
    btnType="text"
    width="500"
    @before-open="form = {}"
    @submit="onsubmit"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <baseForm1
      v-model="form"
      :option="option"
      style="width: 400px">
    </baseForm1>
  </appDialog>
</template>

<script>
import { exportWrap } from '@/utils'
import {  exportDistributorUserTradeRecordList } from '@/api/organization'
export default {
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      form: {},
      option: {
        column: [
          {
            label: '日期',
            prop: 'tradeTime',
            type: 'daterange'
          }
        ]
      }
    }
  },
  methods: {
    async onsubmit(form, done) {
      const tradeTime = this.form?.tradeTime || []
      const defaultParams = {
        mainAccountUid: $GET(this.data, '0.id', '')
      }
      const params = tradeTime[0]
        ? Object.assign(defaultParams, {
          tradeStartTime: `${tradeTime[0]} 00:00:00`,
          tradeEndTime: `${tradeTime[1]} 23:59:59`
        })
        : defaultParams
      const res = await exportWrap(exportDistributorUserTradeRecordList(params), '交易记录')
      done(res && undefined)
    }
  }
}
</script>

